.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 87px;
}

.page-number {
  margin: 0 5px;
  padding: 5px 10px;
  cursor: pointer;
  border: 1px solid #ddd;
  background-color: #f9f9f9;
  transition: background-color 0.3s, color 0.3s;
}

.page-number:hover {
  background-color: #007bff;
  color: white;
}

.page-number.active {
  font-weight: bold;
  background-color: #007bff;
  color: white;
}

button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}