.splash-screen {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #ffffff; /* Set your desired background color */
}

.rotate-image {
    width: 15%;
    /*Adjustimagesizeasneeded*/animation: spin 2s linear infinite;
    -webkit-animation: spin 2s linear infinite;
    border-radius: 100%;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    -ms-border-radius: 100%;
    -o-border-radius: 100%;
    margin-bottom: 25px;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}