.App {
  text-align: center;
  font-family: "Encode Sans Expanded", sans-serif;
  font-style: normal;
  font-weight: 300;
  height: fit-content;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.header{
  text-align: center;
  margin-top: 25px;
  margin-bottom: 25px;
  font-size: 35px;
  font-weight: bold;
}

.search{
  margin-top: 25px;
  margin-bottom: 25px;
}

.card{
  margin: 25px;
  border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
  border-color: #006064;
  text-align: center;
}

.card-calender{
  border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
  border-color: #006064;
  text-align: center;
  padding: 25px;
}

.submit-button{
  margin-top: 25px;
  margin-bottom: 25px;
  color: #fff;
}

.update-button{
  margin-top: 25px;
  margin-bottom: 25px;
  color: #fff;
}

.card-container{
  padding: 25px;
}

.user-image{
  width: 80%;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.toast{
  color: #fff;
}

.app-container {
  display: flex;
}

.content-container {
  margin-left: 250px;
  padding: 20px;
  flex: 1;
}

.card-intro{
  text-align: justify;
  padding: 25px;
}

.card-home:hover{
  background-color: #619a9c;
  color: #fff;
}

.report-button{
  width: 250px;
  text-align: center;
  margin-left: 750px;
  background-color: #006064;
  border: none;
}

.report-button:hover{
  background-color: #619a9c;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
