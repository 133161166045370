.navbar{
  background-color: #006064;
  font-family: "Encode Sans Expanded", sans-serif;
  font-weight: 300;
  font-style: normal;
  color: #fff;
}

.navbar-link{
  color: #fff;
}

.navbar-link:hover{
  color: #619a9c;
}